import React from "react"
import Layout from "../components/Layout"
import "../css/projects/py_proj1.css"
import { Link } from "gatsby"
import { FaCode } from "react-icons/fa"
import SEO from "../components/SEO"
import Particle8 from "../components/Particle8"

const Py_proj15 = () => {
  return (
    <Layout>
      <SEO
        title="Monte Hall_3 Doors and a Twist"
        description="Awesome python project"
      />
      <Particle8></Particle8>
      <h6>s</h6>

      <section className="noSelect">
        <Link to="/pythonprojectcard/">
          <button type="button" className="back-btn noSelect">
            <span>{"<"}= More Projects</span>
          </button>
        </Link>
      </section>

      <section className="proj-name-wrap noSelect">
        <div className="pyt-img-15">
          <div className="py-1-heading">
            <h1>Monte Hall_3 Doors and a Twist</h1>
          </div>
        </div>
        <div className="git">
          <a
            href="https://github.com/arpitsomani8/Python-Programming-Projects/tree/master/Monte%20Hall_3%20Doors%20And%20a%20Twist"
            target="_blank"
            rel="noopener noreferrer"
            title="Github code"
          >
            <FaCode className="git-btn"></FaCode>
          </a>
        </div>

        <div className="proj-1">
          <div className="p1">
            <h3>Concept Behind It :</h3>
            <p>
              We have 3 doors.
              <br />
              Two door contains a goat and one contain a prize,
              <br />
              say, BMW car.
              <br />
              <br />
              Now, you have to choose one of the door,
              <br />
              Wait, the game is not finished yet...
              <br />
              <br />
              Then, one of the door containing goat is opened <br />
              and a reconsider chance (swap) is given to change the choosen
              door.
              <br />
              Then result are declared.
              <br />
              <br />
              Well, lets check if you are lucky enough to win a BMW car...
            </p>

            <h3>Skills Behind It :</h3>
            <div className="py-skill-1">
              <button type="button" className="btn-py-1" disabled>
                <span>Random</span>
              </button>
              <button type="button" className="btn-py-1" disabled>
                <span>Python</span>
              </button>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Py_proj15
